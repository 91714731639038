/* nav{
    padding: .5rem 2rem;
} */

nav li a{
font-size: 1rem;
}

nav li a:hover{
color: #00923F;
}

@media screen and (min-width: 1024px) {
nav{
    padding: .5rem 4rem 3rem 4rem;
}
}

/* Overlay */
.overlay {
    height: 0%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    padding: 0;
    background: linear-gradient(0deg, rgba(15,26,4,1) 0%, rgba(82,141,18,0.95) 100%);
    overflow-y: hidden;
    transition: 0.5s;
  }
  
  .overlay-content {
    position: relative;
    top: 5%;
    width: 100%;
    text-align: center;
    margin-top: 1.875rem;
    padding: 0 2rem;
  }
  
  .overlay a {
    padding: .5rem;
    text-decoration: none;
    font-size: 1.25rem;
    color: #dadada;
    display: block;
    transition: 0.3s;
    text-align: left;
  }
  
  .overlay a:hover, .overlay a:focus {
    color: rgb(249 115 22);
    padding: .5rem;
    background-color: white;
    border-radius: 1.25rem;
    font-weight: 700;
    cursor: pointer;
  }
  
  .overlay .closebtn {
    position: absolute;
    top: 1.25rem;
    right: 2.8125rem;
    font-size: 3.75rem;
  }

  .fullScreen a {
    font-size: 20px;
  }

  .fullScreen a:hover, .fullScreen a:focus{
    color: rgb(249 115 22);
    text-decoration: underline ;
    text-underline-offset: 4px;
    cursor: pointer;
  }
  
  @media screen and (max-height: 450px) {
    .overlay {overflow-y: auto;}
    .overlay .closebtn {
    font-size: 2.5rem;
    top: .9375rem;
    right: 2.1875rem;
    }
  }