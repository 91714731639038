.accordion-item {
    list-style: none;
  }
  .accordion-toggle {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.5em;
  }

  .accordion-toggle h3 {
    margin: 0;
  }
  .accordion-content {
    padding: 1em;
  }
