@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&family=Raleway:wght@600;800&display=swap');
@import url(./styles/navbar.css);
@import url(./styles/accordian.css);


body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1800px;
  margin-left: auto !important;
  margin-right: auto !important;
  line-height: 28px !important;
  font-size: 16px;
}

@media screen and (min-width: 1024px) {
  body{
    font-size: 18px;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

h1,h3, h5{
  font-family: 'Raleway', sans-serif
}

h1{font-weight: 800 !important;}

h3{font-weight: 600;}

section {
padding: 8rem 2.5rem;
}

@media screen and (min-width: 768px) {
  section {
    padding: 8rem 6rem;
  } 
}

section.bgmain{
  background-image: url('./imgs/bg.jpg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  padding: initial;

}

.bgorange{
  background-color: #F38722;
}

span.swiper-pagination-bullet-active{
  background-color: #00923F;
}

.txtorange{color: #F38722;}

div.swiper-pagination{
  position: relative;
  margin-top: 3rem;
}

#about{
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 95%);
}

footer a{
  cursor: pointer;
}